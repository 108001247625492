import { render, staticRenderFns } from "./ProductCompare.vue?vue&type=template&id=5c85c8a0&scoped=true&"
import script from "./ProductCompare.vue?vue&type=script&lang=js&"
export * from "./ProductCompare.vue?vue&type=script&lang=js&"
import style0 from "./ProductCompare.vue?vue&type=style&index=0&id=5c85c8a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c85c8a0",
  null
  
)

export default component.exports